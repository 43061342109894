globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"54999f3-dev"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/auth";
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.SENTRY_DSN,

  tracesSampleRate: 1,
  profilesSampleRate: 1.0,

  debug: false,

  replaysOnErrorSampleRate: parseFloat(process.env.SENTRY_REPLAY ?? '0'),
  environment: process.env.FULL_ENV,
  release: process.env.SENTRY_RELEASE,

  integrations: [
    Sentry.replayIntegration,
    Sentry.browserProfilingIntegration,
    Sentry.browserTracingIntegration,
  ],
});
